import './App.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import { BrowserRouter, Routes, Route, ScrollRestoration } from "react-router-dom";
import Home from './pages/home';
import PrivacyPolicy from './pages/privacy_policy';
import 'bootstrap/dist/css/bootstrap.css';


function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
