import '../App.css';
import { useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import { Link } from 'react-router-dom';
function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
   <>
   <Header />
   <main>
        <section className="privacy-policy">
            <div className="container">

                <h1>Privacy Policy</h1>
                <h3 className="fw-bold">Our Dedication to Your Privacy</h3>
                <p>At Solutionly, we value your privacy. To safeguard it effectively, Solutionly outlines our information practices, detailing how we gather, utilize, and protect your data.</p>
                <h3 className="fw-bold">What Information Do We Gather?</h3>
                <p>We collect data submitted through our contact forms and pages. This information is provided voluntarily and explicitly by you – we do not gather it without your conscious decision to share it with us.</p>
                <p>This may include your name, email address, and phone number, related to projects, services, and billing. Information may also be acquired through phone or email conversations with official Solutionly representatives.</p>
                <p>Furthermore, Solutionly may collect third-party information specifically related to you, your project, or requested services.</p>
                <h3 className="fw-bold">How Do We Utilize the Information We Collect?</h3>
                <p>The data gathered by Solutionly is used to provide digital services to you. This includes web support, customer service, project updates via email, newsletters, and direct contact regarding your project and services.</p>
                <p>Solutionly does not disclose information to external parties unless necessary to fulfill the services you've requested. Solutionly reserves the right to share applicable information in accordance with official judicial practices, such as a valid subpoena or court order.</p>
                <h3 className="fw-bold">Do We Employ Cookies?</h3>
                <p>Cookies are small files websites place on individual computer hard drives through web browsers. Users must explicitly consent to cookies from every website that uses them. Cookies allow a website to recognize a specific browser, capture, and retain certain information.</p>
                <p>Solutionly utilizes cookies to remember, understand, and save your preferences for future visits to our website. Cookies assist us in gathering data on website traffic, engagement, and interactions, enabling us to enhance future online experiences, tools, and services.</p>
                <p>The information collected by our cookies does not contain personal data that can identify you specifically. Conversely, the anonymous information gathered by the cookies is sent to Google for the sole purpose of creating anonymous web activity reports for the domain <a href="#">https://www.solutionly.in/</a>. This data aids in optimizing customer experience, content, and marketing efforts for <a href="#">https://www.solutionly.in/</a>.</p>
                <p>Furthermore, no personally identifiable information is collected on this website unless explicitly submitted through a form, such as our "Work With Us" page.</p>
                <h3 className="fw-bold">Remarketing</h3>
                <p>Solutionly employs cookies to display remarketing advertisements, based on a user’s past visits to <a href="#">https://www.solutionly.in/</a>, which may appear on other websites across the internet.</p>
                <p>Users can opt out of cookies via Google Ads Preferences Manager at any time.</p>
                <h5>Third-Party Websites</h5>
                <p>Solutionly's website may contain links to third-party websites. We do not have control over these websites or their content, as they have their own distinct and independent privacy policies. Therefore, we do not assume responsibility or liability for these websites.</p>
                <p>Additionally, Solutionly does not endorse these websites, their information, or products. Visiting third-party websites linked on our site is at your own discretion and risk.</p>
                <h3 className="fw-bold">Solutionly's Data Security Commitment</h3>
                <p>To uphold data security, ensure proper information use, and maintain data accuracy, Solutionly employs appropriate practices to safeguard online and offline data, including electronic and managerial procedures.</p>
                <p>Every online credit card transaction is conducted on secure servers via a secure SSL connection. These transactions comply with industry standards and all billing information, including credit card details, is promptly deleted upon termination or completion of a client project.</p>
                <h3 className="fw-bold">Solutionly's Commitment to Protecting Children’s Privacy Online</h3>
                <p>Solutionly is dedicated to safeguarding children’s privacy and does not knowingly collect or maintain information from individuals under 13. Our website is not designed to attract individuals under 13 years of age and is not intended for users under 18.</p>
                <p>Should we become aware of any individuals under 18 submitting personal information on our website, Solutionly will delete this information and refrain from using it for any purpose.</p>
                <p>We encourage parents and legal guardians to discuss the potential risks of sharing personal information online with their children.</p>
                <h3 className="fw-bold">Our Privacy Policy and Your Consent</h3>
                <p>By utilizing our website, you consent to Solutionly's privacy policy.</p>
                <p>Any changes to our privacy policy will be posted on this webpage.</p>
                <h3 className="fw-bold">Your Privacy Choices and Rights</h3>
                <p>You are not obliged to provide personal information to access the majority of features on our website. Additionally, you can opt out of certain activities by sending us an opt-out email or by using the opt-out options provided within the activities themselves.</p>
                <h3 className="fw-bold">Contacting Solutionly</h3>
                <p className='mb-2'>If you have any inquiries regarding our privacy policy, please contact</p>
                <div>
                    <p className='mb-5'>Solutionly.in</p>
                    <p className='mb-2 mt-5'><span className='bd-highlight badge bg-dark'>Address </span> </p>
                    <p className='mb-2'> 76 D, Phase IV, Sector 19,</p>
                    <p className='mb-2'> Gurugram, Sarhol,</p>
                    <p className='mb-5'> Haryana 122001</p>
                    <p className='mb-2'>contact@solutionly.in</p>
                    <p>(+91)95990 60970</p>
                </div>
            </div>
        </section>
    </main>
    <Footer />
     </>
  );
}

export default Home;
