import logo from '../assets/img/logo.png';
import '../App.css';
import { Link } from 'react-router-dom';


function Footer() {
  return (
    <footer>
         {/* <!-- footer --> */}
         <section className="footer-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 mb-5 mb-lg-0">
                            <h5 className="mb-4">Services</h5>
                            <ul className="list-unstyled ps-0">
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">User Research</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">UX Design</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">UI Design</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">Usability Testing</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">Graphic Design</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">Digital Marketing</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">App Development</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">Web Development</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-5 mb-lg-0">
                            <h5 className="mb-4">Expertise</h5>
                            <ul className="list-unstyled ps-0">
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">Expertise</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">Fintech</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">Healthcare</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">SAAS</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">Logistics</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">Edtech</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">Insurance</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">Aviation</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">Media</a></li>
                                <li><a href="javascript:void(0)" className="text-decoration-none text-white">Travel & Hospitality</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-4 mb-5 mb-lg-0">
                            <h5 className="mb-4">Contacts</h5>
                            <ul className="list-unstyled ps-0">
                                <li><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-envelope me-3" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                </svg><a href="javascript:void(0)" className="text-decoration-none text-white">contact@solutionly.in</a></li>
                                <li><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-telephone me-3" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                </svg>+91 - 95990 60970</li>
                                <li className='d-flex'>
                                <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-geo-alt-fill me-3" viewBox="0 0 16 16">
                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
                                    </svg>
                                </span>
                                    <span>
                                    3rd Floor, Orchid Centre<br/>
                                    Near IILM Institute, Sector 53<br/>
                                    Next to Sector 54<br/>
                                    Gurugram, Haryana - 122022
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
    <div className="cotainer">
      <div className="text-center py-5 my-2">
        <a href='https://www.instagram.com/solutionly.in2024/' target='_blank'>
        <img src={require("../assets/img/socialmedia/instagram.png")} className="img-fluid" alt="instagram" />
        </a>
        <a href='https://www.linkedin.com/company/solutionly-in/' target='_blank'>
        <img src={require("../assets/img/socialmedia/linkedin.png")} className="img-fluid ms-4" alt="linkedin" />
        </a>
        {/* <img src={require("../assets/img/socialmedia/whatsapp.png")} className="img-fluid ms-4" alt="whatsapp" /> */}
        <a href='https://www.facebook.com/profile.php?id=61558030113390' target='_blank'>
        <img src={require("../assets/img/socialmedia/facebook.png")} className="img-fluid ms-4" alt="facebook" />
        </a>
        <a href=' http://www.youtube.com/@Solutionly-gd7kr' target='_blank'>
        <img src={require("../assets/img/socialmedia/youtube.png")}className="img-fluid ms-4" alt="youtube" />
        </a>
      </div>
      <div className="d-md-flex justify-content-center text-center my-2">
          <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-c-circle me-3" viewBox="0 0 16 16">
            <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.146 4.992c-1.212 0-1.927.92-1.927 2.502v1.06c0 1.571.703 2.462 1.927 2.462.979 0 1.641-.586 1.729-1.418h1.295v.093c-.1 1.448-1.354 2.467-3.03 2.467-2.091 0-3.269-1.336-3.269-3.603V7.482c0-2.261 1.201-3.638 3.27-3.638 1.681 0 2.935 1.054 3.029 2.572v.088H9.875c-.088-.879-.768-1.512-1.729-1.512"/>
          </svg>2024. All rights reserved</p>
          <p className="ms-lg-5"><Link to="/privacy_policy" className="text-dark text-decoration-none">Privacy Policy</Link></p>
      </div>
    </div>
 </footer>
    )
    }

    export default Footer;